var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ticket-search",attrs:{"fluid":""}},[_c('TicketsSectionSearch',{on:{"search":_vm.getItems}}),_c('v-tabs',{staticClass:"mt-6",attrs:{"background-color":"transparent"},on:{"change":_vm.onTabChange},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-access-point ")]),_vm._v(" Elenco ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-access-point ")]),_vm._v(" Calendario ")],1),_c('v-tab-item',{staticClass:"mt-4"},[_c('BaseTable',{staticStyle:{"margin-top":"0px !important"},attrs:{"title":"Tickets","headers":_vm.headers,"items":_vm.items,"total-rows":_vm.totalRows,"loading":_vm.loading,"exportable":_vm.canUser('tickets', 'export'),"actions":_vm.actions,"sortby":_vm.sortby},on:{"refresh":_vm.onRefresh,"export":function (format) { return _vm.download({ format: format }); }},scopedSlots:_vm._u([{key:"item.external_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.external_id))])]}},{key:"item.effective_priority",fn:function(ref){
var item = ref.item;
return [(item.effective_priority >= 15)?_c('v-chip',{attrs:{"small":"","label":"","color":"red"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-decagram-outline")]),_vm._v(" ALTA ")],1):(
              item.effective_priority > 10 && item.effective_priority < 15
            )?_c('v-chip',{attrs:{"small":"","label":"","color":"orange"}},[_vm._v(" MEDIA ")]):_c('v-chip',{attrs:{"label":"","color":"info","small":""}},[_vm._v("Normale")])]}},{key:"item.activity",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity.macro)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.activity.category)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.activity.subcategory)+" ")]}},{key:"item.customers_user",fn:function(ref){
            var item = ref.item;
return [(item.customers_user)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm._f("get")(item,['customers_user', 'last_name'], ''))+" "+_vm._s(_vm._f("get")(item,['customers_user', 'first_name'], ''))+" ")]),_c('br'),_c('small',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("get")(item,['customers_user', 'mobile_phone_number'], ''))+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"item.tobe_managed_by",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.sla_1)?_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" La lavorazione non è stata presa in carico entro lo SLA previsto. ")])]),_c('span',{class:{ 'font-weight-bold': item.sla_1 }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.tobe_managed_by,'DD/MM/YYYY HH:mm', '-'))+" ")])]}},{key:"item.tobe_closed_by",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.sla_2)?_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" La lavorazione non è stata chiusa entro lo SLA previsto. ")])]),_c('span',{class:{ 'font-weight-bold': item.sla_2 }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.tobe_closed_by,'DD/MM/YYYY HH:mm', '-'))+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"my-6",attrs:{"small":"","color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}])})],1),_c('v-tab-item',{staticClass:"mt-4"},[_c('v-card',{attrs:{"elevation":"0","outlined":"","tile":""}},[_c('v-card-title',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2","small":"","tile":""},on:{"click":_vm.setToday}},[_vm._v(" Oggi ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$refs.calendar.title))+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","tile":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.calendar.types[_vm.calendar.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.calendar.types),function(label,key){return _c('v-list-item',{key:key,on:{"click":function () { return _vm.viewChange(key); }}},[_c('v-list-item-title',[_vm._v(_vm._s(label))])],1)}),1)],1)],1)],1),_c('v-card-text',{attrs:{"height":840}},[_c('v-calendar',{ref:"calendar",attrs:{"start":_vm.startingDate,"weekdays":_vm.calendar.weekdays['mon-sun'],"events":_vm.calendarEvents,"event-height":20,"first-interval":7,"interval-minutes":60,"interval-count":14,"interval-height":60,"type":_vm.calendar.type,"event-overlap-mode":"stack","disabled":""},on:{"click:event":_vm.onEventClick,"click:more":_vm.viewDay,"click:date":_vm.viewDay},model:{value:(_vm.calendar.value),callback:function ($$v) {_vm.$set(_vm.calendar, "value", $$v)},expression:"calendar.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"min-width":"400px","max-width":"450px","flat":"","elevation":"0"}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selectedEvent.name))])],1),_c('v-card-text',[_c('p',[_c('strong',[_vm._v("Attività:")]),_c('br'),_vm._v(" "+_vm._s(_vm.selectedEvent.macro)+" - "+_vm._s(_vm.selectedEvent.category)+" - "+_vm._s(_vm.selectedEvent.subcategory)+" ")]),_c('p',[_c('strong',[_vm._v("In Carico A:")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("placeholder")(_vm.selectedEvent.managed_by,'-'))+" ")]),_c('p',[_c('strong',[_vm._v("Note:")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("placeholder")(_vm.selectedEvent.notes,'-'))+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","to":{
                    name: 'tickets_detail',
                    params: { id: _vm.selectedEvent.id },
                  }}},[_vm._v(" Vai al ticket ")])],1)],1)],1)],1)],1),(!_vm.calendarEvents.length)?_c('v-alert',{staticClass:"mt-3",attrs:{"type":"info","dense":"","prominent":""}},[_vm._v(" Attenzione: Non ci sono slot appuntamenti nel range temporale selezionato. ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }