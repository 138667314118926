<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem v-model="external_id" name="Identificativo" dense :xl="2" />
      <FormCustomers
        v-if="!currentCustomerId"
        v-model="customer_id"
        :customer_id="customer_id"
        name="Cliente"
        dense
        clearable
      />
      <FormCustomersUsers
        v-model="customer_user_id"
        :customer_id="customer_id"
        label="Utente"
        dense
        clearable
        :lg="3"
      />
      <FormUsers
        v-model="managed_by"
        label="Operatore"
        :use-username="true"
        dense
        clearable
        :additional-filters="{ role_id: [6, 2] }"
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="macro"
        :values="
          macrosByContext({
            customerId: customer_id,
            category,
            subcategory,
          })
        "
        type="autocomplete"
        name="Commessa"
        chips
        deletable-chips
        dense
      />

      <FormItem
        v-model="category"
        :values="
          categoriesByContext({
            customerId: customer_id,
            macro,
            subcategory,
          })
        "
        type="autocomplete"
        name="Categoria"
        chips
        deletable-chips
        dense
      />
      <FormItem
        v-model="subcategory"
        :values="
          subcategoriesByContext({
            customerId: customer_id,
            macro,
            category,
          })
        "
        type="autocomplete"
        name="Sottocategoria"
        chips
        deletable-chips
        dense
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="type"
        type="autocomplete"
        :values="types"
        name="Tipo"
        chips
        deletable-chips
        dense
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="status"
        name="Stato Lavorazione"
        type="autocomplete"
        chips
        deletable-chips
        dense
        :values="statusesByContext({ macro, category, resolution })"
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="resolution"
        name="Esito Lavorazione"
        type="autocomplete"
        chips
        deletable-chips
        dense
        :values="resolutionsByContext({ macro, category, status })"
        :xl="3"
      />

      <FormItem
        v-model="date_appointment"
        type="datepicker"
        name="Data Appuntamento"
        hint="Ricerca per Data Appuntamento"
        dense
        range
        :xl="2"
      />
      <FormItem
        v-model="created_at"
        type="datepicker"
        name="Data Inserimento"
        hint="Ricerca per Data Inserimento"
        dense
        range
        :xl="2"
      />
      <FormItem
        v-model="closed_at"
        type="datepicker"
        name="Data Chiusura"
        hint="Ricerca per Data Chiusura"
        dense
        range
        :xl="2"
      />
    </v-row>

    <template v-slot:extra>
      <v-row dense>
        <FormItem v-model="serial_imei" name="Seriale / IMEI / MSISDN" dense />
        <FormItem
          v-model="kasko"
          name="Kasko"
          type="select"
          :values="[
            { value: 'true', text: 'SI' },
            { value: 'false', text: 'NO' },
          ]"
          hint="Ricerca per kasko"
          dense
          :clearable="true"
        />

        <FormChannels
          v-if="!currentCustomerId"
          v-model="channel"
          name="Canale di ingaggio"
          multi
          dense
        />

        <FormItem
          v-if="!currentCustomerId"
          v-model="anomaly"
          type="autocomplete"
          :values="anomalies"
          name="Anomalia"
          single
          dense
          clearable
        />
      </v-row>
    </template>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormChannels from '@components/forms/FormChannels.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormCustomersUsers from '@components/forms/FormCustomersUsers.vue'
import FormUsers from '@components/forms/FormUsers.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'tickets/getFiltersFields',
  mutationType: 'tickets/SET_FILTER_FIELDS',
})

export default {
  name: 'TicketsSectionSearch',
  components: {
    FormCustomers,
    FormCustomersUsers,
    FormUsers,
    FormChannels,
    FormItem,
    BaseSectionSearch,
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'anomaly',
      'assigned_at',
      'category',
      'channel',
      'closed_at',
      'created_at',
      'customer_id',
      'customer_user_id',
      'date_appointment',
      'external_id',
      'kasko',
      'macro',
      'managed_at',
      'managed_by',
      'resolution',
      'serial_imei',
      'solicited_at',
      'status',
      'subcategory',
      'tobe_closed_by',
      'tobe_managed_by',
      'type',
    ]),
    ...mapState('ticketsActivities', ['types']),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
      subcategoriesByContext: 'subcategoriesByContext',
    }),
    ...mapState('tickets', ['anomalies']),
    ...mapGetters('ticketsStatuses', {
      statusesByContext: 'statusesByContext',
    }),
    ...mapGetters('ticketsResolutions', {
      resolutionsByContext: 'resolutionsByContext',
    }),
  },
  async mounted() {
    await this.getDropdownList({})
  },
  methods: {
    ...mapActions('ticketsActivities', ['getDropdownList']),
    ...mapMutations('tickets', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>
