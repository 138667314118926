<template>
  <v-container class="ticket-search" fluid>
    <TicketsSectionSearch @search="getItems" />

    <v-tabs
      v-model="currentTab"
      class="mt-6"
      background-color="transparent"
      @change="onTabChange"
    >
      <v-tab>
        <v-icon left> mdi-access-point </v-icon>
        Elenco
      </v-tab>
      <v-tab>
        <v-icon left> mdi-access-point </v-icon>
        Calendario
      </v-tab>

      <v-tab-item class="mt-4">
        <BaseTable
          style="margin-top: 0px !important"
          title="Tickets"
          :headers="headers"
          :items="items"
          :total-rows="totalRows"
          :loading="loading"
          :exportable="canUser('tickets', 'export')"
          :actions="actions"
          :sortby="sortby"
          @refresh="onRefresh"
          @export="(format) => download({ format })"
        >
          <template v-slot:item.external_id="{ item }">
            <v-chip label>{{ item.external_id }}</v-chip>
          </template>

          <template v-slot:item.effective_priority="{ item }">
            <v-chip
              v-if="item.effective_priority >= 15"
              small
              label
              color="red"
            >
              <v-icon left>mdi-alert-decagram-outline</v-icon>
              ALTA
            </v-chip>
            <v-chip
              v-else-if="
                item.effective_priority > 10 && item.effective_priority < 15
              "
              small
              label
              color="orange"
            >
              MEDIA
            </v-chip>
            <v-chip v-else label color="info" small>Normale</v-chip>
          </template>
          <template v-slot:item.activity="{ item }">
            {{ item.activity.macro }}
            <v-icon small>mdi-chevron-right</v-icon>
            {{ item.activity.category }}
            <v-icon small>mdi-chevron-right</v-icon>
            {{ item.activity.subcategory }}
          </template>
          <template v-slot:item.customers_user="{ item }">
            <span v-if="item.customers_user">
              <strong>
                {{ item | get(['customers_user', 'last_name'], '') }}
                {{ item | get(['customers_user', 'first_name'], '') }}
              </strong>
              <br />
              <small class="text--secondary">
                {{ item | get(['customers_user', 'mobile_phone_number'], '') }}
              </small>
            </span>
            <span v-else> - </span>
          </template>

          <template v-slot:item.tobe_managed_by="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.sla_1"
                  left
                  color="red"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-clock-alert
                </v-icon>
              </template>
              <span>
                La lavorazione non è stata presa in carico entro lo SLA
                previsto.
              </span>
            </v-tooltip>

            <span :class="{ 'font-weight-bold': item.sla_1 }">
              {{ item.tobe_managed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
            </span>
          </template>
          <template v-slot:item.tobe_closed_by="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.sla_2"
                  left
                  color="red"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-clock-alert
                </v-icon>
              </template>
              <span>
                La lavorazione non è stata chiusa entro lo SLA previsto.
              </span>
            </v-tooltip>
            <span :class="{ 'font-weight-bold': item.sla_2 }">
              {{ item.tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
            </span>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip small :color="getColor(item.status)" dark class="my-6">
              {{ item.status }}
            </v-chip>
          </template>
        </BaseTable>
      </v-tab-item>
      <v-tab-item class="mt-4">
        <v-card elevation="0" outlined tile>
          <v-card-title>
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                small
                tile
                @click="setToday"
              >
                Oggi
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title | capitalize }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    outlined
                    tile
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ calendar.types[calendar.type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(label, key) in calendar.types"
                    :key="key"
                    @click="() => viewChange(key)"
                  >
                    <v-list-item-title>{{ label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-card-title>
          <v-card-text :height="840">
            <v-calendar
              ref="calendar"
              v-model="calendar.value"
              :start="startingDate"
              :weekdays="calendar.weekdays['mon-sun']"
              :events="calendarEvents"
              :event-height="20"
              :first-interval="7"
              :interval-minutes="60"
              :interval-count="14"
              :interval-height="60"
              :type="calendar.type"
              event-overlap-mode="stack"
              disabled
              @click:event="onEventClick"
              @click:more="viewDay"
              @click:date="viewDay"
            />
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card min-width="400px" max-width="450px" flat elevation="0">
                <v-toolbar>
                  <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <p>
                    <strong>Attività:</strong><br />
                    {{ selectedEvent.macro }} - {{ selectedEvent.category }} -
                    {{ selectedEvent.subcategory }}
                  </p>
                  <p>
                    <strong>In Carico A:</strong><br />
                    {{ selectedEvent.managed_by | placeholder('-') }}
                  </p>
                  <p>
                    <strong>Note:</strong><br />
                    {{ selectedEvent.notes | placeholder('-') }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    :to="{
                      name: 'tickets_detail',
                      params: { id: selectedEvent.id },
                    }"
                  >
                    Vai al ticket
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-card-text>
        </v-card>

        <v-alert
          v-if="!calendarEvents.length"
          type="info"
          dense
          prominent
          class="mt-3"
        >
          Attenzione: Non ci sono slot appuntamenti nel range temporale
          selezionato.
        </v-alert>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { createHelpers } from 'vuex-map-fields'
import { get } from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import TicketsSectionSearch from '@components/tickets/TicketsSectionSearch.vue'

const { mapFields: ticketFilters } = createHelpers({
  getterType: 'tickets/getFiltersFields',
  mutationType: 'tickets/SET_FILTER_FIELDS',
})

export default {
  name: 'TicketsSearch',
  components: { TicketsSectionSearch, BaseTable },
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calendar: {
        value: '',
        type: 'week',
        types: {
          month: 'Mese',
          week: 'Settimana',
          day: 'Giorno',
        },
        weekdays: {
          'mon-sun': [1, 2, 3, 4, 5, 6, 0],
        },
      },
      currentTab: 'list',
      tabs: [
        {
          key: 'list',
          title: 'Elenco',
        },
        {
          key: 'calendar',
          title: 'Calendario',
        },
      ],
      actions: [
        {
          key: 'view',
          label: 'Val al Ticket',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          to: (item) => ({ name: 'tickets_detail', params: { id: item.id } }),
          onItemCondition: () => this.canUser('tickets', 'read'),
        },
      ],

      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      items: (state) => state.pagination.rows,
      totalRows: (state) => state.pagination.totalRows,
      totalPages: (state) => state.pagination.totalPages,
      loading: (state) => state.pagination.loading,
      ticketList: (state) => state.list,
    }),
    ...ticketFilters({
      dateAppointment: 'date_appointment',
      category: 'category',
    }),
    startingDate() {
      if (this.showAll) return
      return this.$moment().format('YYYY-MM-DD')
    },
    calendarEvents() {
      return this.ticketList.map((ticket) => ({
        id: ticket.id,
        name: ticket.customer?.common_name || 'N/A',
        start: ticket.start,
        end: ticket.end,
        color: ticket.color,
        timed: true,
        ...ticket.activity,
        status: ticket.status,
        resolution: ticket.resolution,
        notes: ticket.customer_notes,
        managed_by: ticket.managed_by,
      }))
    },
    displayedMonth() {
      const date = this.dateAppointment?.[0] || this.calendar.value
      return this.$moment(date).format('MMMM')
    },

    sortby() {
      if (this.userType === 'customer') return ['created_at']

      return ['effective_priority', 'created_at']
    },
    headers() {
      return [
        {
          text: '#ID',
          align: 'start',
          value: 'external_id',
        },

        ...(this.userType !== 'customer'
          ? [
              {
                text: 'Priorità',
                value: 'effective_priority',
              },
            ]
          : []),

        {
          text: 'Cliente',
          value: 'customer.common_name',
        },
        {
          text: 'Utente',
          value: 'customers_user',
        },
        {
          text: 'Attività',
          align: 'start',
          value: 'activity',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Esito',
          value: 'resolution',
        },
        {
          text: 'In Carico',
          value: 'managed_at',
        },
        {
          text: 'Operatore', 
          value: 'managed_by'
        },
        {
          text: 'Chiusura',
          value: 'closed_at',
        },
        {
          text: 'Data Appuntamento',
          value: 'date_appointment',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
      ]
    },
  },
  watch: {
    // Every time the calendar value changes manually update the bounds
    'calendar.value': async function(value) {
      this.adjustDateBounds({ start: { date: value } })
      await this.getCalendarEvents()
    },
  },
  mounted() {
    const filters = get(this.$route, ['params', 'filters'], {})
    // Preset all the filters passed by the route
    Object.entries(filters).forEach(([key, value]) => {
      this[key] = value
    })
    this.setScopes([
      'customer',
      'user',
      'activity',
      'location',
      'subscription',
      'asset',
      'sla',
      'priority',
    ])
  },
  beforeDestroy() {
    this.resetFilters()
    this.resetPaginated()
    this.resetList()
    this.setScopes([])
  },
  methods: {
    ...mapActions('tickets', ['getItems', 'download', 'getDropdownList']),
    ...mapMutations('tickets', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
      resetPaginated: 'RESET_PAGINATED_ITEMS',
      resetList: 'RESET_LIST',
    }),
    async onTabChange(index) {
      const { key } = this.tabs[index]
      switch (key) {
        case 'list':
          await this.getItems()
          break

        case 'calendar':
          {
            const fallback =
              this.dateAppointment?.[0] ||
              this.calendar.value ||
              this.$moment().format('YYYY-MM-DD HH:mm')

            const date = {
              start: {
                date: fallback,
              },
            }
            this.adjustDateBounds(date)
            await this.getCalendarEvents()
            this.$refs.calendar.checkChange()
            this.$refs.calendar.scrollToTime('08:00')
          }
          break

        default:
          break
      }
    },

    // This is used when table changes ctx
    onRefresh(ctx) {
      this.getItems(ctx)
    },

    // The followings are all linked to the calendar
    setToday() {
      this.calendar.value = this.$moment().format('YYYY-MM-DD')
    },

    getColor(status) {
      switch (status) {
        case 'waiting':
          return 'gray'
        case 'offline':
          return 'red'
        default:
          return 'green'
      }
    },
    noOp() {},

    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    viewDay({ date }) {
      this.calendar.value = date
      this.calendar.type = 'day'
    },

    // When calendar view changes type eg from month to week retrieve the
    // last valid calendar value or use today
    async viewChange(type) {
      this.calendar.type = type
      const date = {
        start: {
          date: this.calendar.value || this.$moment().format('YYYY-MM-DD'),
        },
      }
      this.adjustDateBounds(date)
      await this.getCalendarEvents()
    },

    // Calendar handling
    adjustDateBounds({ start: { date } }) {
      let startDate
      let endDate
      switch (this.calendar.type) {
        case 'month':
          startDate = this.$moment(date)
            .startOf('month')
            .format('YYYY-MM-DD')
          endDate = this.$moment(date)
            .endOf('month')
            .format('YYYY-MM-DD')

          break
        case 'week':
          startDate = this.$moment(date)
            .startOf('week')
            .format('YYYY-MM-DD')
          endDate = this.$moment(date)
            .endOf('week')
            .format('YYYY-MM-DD')

          break
        case 'day':
          startDate = this.$moment(date)
            .startOf('day')
            .format('YYYY-MM-DD')
          endDate = this.$moment(date)
            .endOf('day')
            .format('YYYY-MM-DD')

          break
      }
      this.dateAppointment = [startDate, endDate]
    },

    async getCalendarEvents() {
      await this.getDropdownList({
        scopes: ['customer', 'activity', 'calendar'],
      })
    },

    onEventClick({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
}
</script>

<style>
.v-tabs-items {
  background-color: transparent !important;
}
</style>
