var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSectionSearch',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"name":"Seriale / IMEI / MSISDN","dense":""},model:{value:(_vm.serial_imei),callback:function ($$v) {_vm.serial_imei=$$v},expression:"serial_imei"}}),_c('FormItem',{attrs:{"name":"Kasko","type":"select","values":[
          { value: 'true', text: 'SI' },
          { value: 'false', text: 'NO' } ],"hint":"Ricerca per kasko","dense":"","clearable":true},model:{value:(_vm.kasko),callback:function ($$v) {_vm.kasko=$$v},expression:"kasko"}}),(!_vm.currentCustomerId)?_c('FormChannels',{attrs:{"name":"Canale di ingaggio","multi":"","dense":""},model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=$$v},expression:"channel"}}):_vm._e(),(!_vm.currentCustomerId)?_c('FormItem',{attrs:{"type":"autocomplete","values":_vm.anomalies,"name":"Anomalia","single":"","dense":"","clearable":""},model:{value:(_vm.anomaly),callback:function ($$v) {_vm.anomaly=$$v},expression:"anomaly"}}):_vm._e()],1)]},proxy:true}])},[_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"name":"Identificativo","dense":"","xl":2},model:{value:(_vm.external_id),callback:function ($$v) {_vm.external_id=$$v},expression:"external_id"}}),(!_vm.currentCustomerId)?_c('FormCustomers',{attrs:{"customer_id":_vm.customer_id,"name":"Cliente","dense":"","clearable":""},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}}):_vm._e(),_c('FormCustomersUsers',{attrs:{"customer_id":_vm.customer_id,"label":"Utente","dense":"","clearable":"","lg":3},model:{value:(_vm.customer_user_id),callback:function ($$v) {_vm.customer_user_id=$$v},expression:"customer_user_id"}}),_c('FormUsers',{attrs:{"label":"Operatore","use-username":true,"dense":"","clearable":"","additional-filters":{ role_id: [6, 2] }},model:{value:(_vm.managed_by),callback:function ($$v) {_vm.managed_by=$$v},expression:"managed_by"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('FormItem',{attrs:{"values":_vm.macrosByContext({
          customerId: _vm.customer_id,
          category: _vm.category,
          subcategory: _vm.subcategory,
        }),"type":"autocomplete","name":"Commessa","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.macro),callback:function ($$v) {_vm.macro=$$v},expression:"macro"}}),_c('FormItem',{attrs:{"values":_vm.categoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          subcategory: _vm.subcategory,
        }),"type":"autocomplete","name":"Categoria","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('FormItem',{attrs:{"values":_vm.subcategoriesByContext({
          customerId: _vm.customer_id,
          macro: _vm.macro,
          category: _vm.category,
        }),"type":"autocomplete","name":"Sottocategoria","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.subcategory),callback:function ($$v) {_vm.subcategory=$$v},expression:"subcategory"}}),(!_vm.currentCustomerId)?_c('FormItem',{attrs:{"type":"autocomplete","values":_vm.types,"name":"Tipo","chips":"","deletable-chips":"","dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):_vm._e(),(!_vm.currentCustomerId)?_c('FormItem',{attrs:{"name":"Stato Lavorazione","type":"autocomplete","chips":"","deletable-chips":"","dense":"","values":_vm.statusesByContext({ macro: _vm.macro, category: _vm.category, resolution: _vm.resolution })},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}):_vm._e(),(!_vm.currentCustomerId)?_c('FormItem',{attrs:{"name":"Esito Lavorazione","type":"autocomplete","chips":"","deletable-chips":"","dense":"","values":_vm.resolutionsByContext({ macro: _vm.macro, category: _vm.category, status: _vm.status }),"xl":3},model:{value:(_vm.resolution),callback:function ($$v) {_vm.resolution=$$v},expression:"resolution"}}):_vm._e(),_c('FormItem',{attrs:{"type":"datepicker","name":"Data Appuntamento","hint":"Ricerca per Data Appuntamento","dense":"","range":"","xl":2},model:{value:(_vm.date_appointment),callback:function ($$v) {_vm.date_appointment=$$v},expression:"date_appointment"}}),_c('FormItem',{attrs:{"type":"datepicker","name":"Data Inserimento","hint":"Ricerca per Data Inserimento","dense":"","range":"","xl":2},model:{value:(_vm.created_at),callback:function ($$v) {_vm.created_at=$$v},expression:"created_at"}}),_c('FormItem',{attrs:{"type":"datepicker","name":"Data Chiusura","hint":"Ricerca per Data Chiusura","dense":"","range":"","xl":2},model:{value:(_vm.closed_at),callback:function ($$v) {_vm.closed_at=$$v},expression:"closed_at"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }